import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardGroup } from "react-bootstrap";
import TarjetaItemBlog from "../Tarjeta/TarjetaItemBlog";
import { getBlog } from "../../services/ApiServices";
import Load from "../Utils/Load";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function ContenedorBlog({ categoria }) {
  const [fetchBlog, setFetchBlog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [lang, setLang] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location]);

  useEffect(() => {
    const fetchBlogList = async () => {
      setIsLoading(true);
      try {
        if (lang) {
          const response = await getBlog(lang, categoria || "");
          if (Array.isArray(response)) {
            setFetchBlog(response);
            if (response.length === 0) {
              navigate("/es/404", { replace: true, state: { statusCode: 404 } });
            }
          } else {
            console.error("La respuesta de la API no es válida:", response);
            setFetchBlog([]);
            navigate("/es/404", { replace: true, state: { statusCode: 404 } });
          }
        }
      } catch (error) {
        console.error("Error al obtener blog:", error);
        navigate("/es/404", { replace: true, state: { statusCode: 404 } });
      } finally {
        setIsLoading(false);
      }
    };
    fetchBlogList();
  }, [lang, categoria, navigate]);

  function Items({ currentItems }) {
    if (!Array.isArray(currentItems) || currentItems.length === 0) {
      return <p>No hay elementos disponibles.</p>;
    }
    return (
      <Row>
        {currentItems.map((item, index) => (
          <TarjetaItemBlog data={item} key={index} />
        ))}
      </Row>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      if (Array.isArray(fetchBlog) && fetchBlog.length > 0) {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(fetchBlog.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(fetchBlog.length / itemsPerPage));
      } else {
        setCurrentItems([]);
        setPageCount(0);
      }
    }, [itemOffset, itemsPerPage]);

    const handlePageClick = (event) => {
      if (Array.isArray(fetchBlog) && fetchBlog.length > 0) {
        const newOffset = (event.selected * itemsPerPage) % fetchBlog.length;
        setItemOffset(newOffset);
      }
    };

    return (
      <Row>
        <Col xs={12}>
          <CardGroup>
            <Items currentItems={currentItems} />
          </CardGroup>
        </Col>
        {pageCount > 1 && (
          <Col xs={12} className="d-flex justify-content-center my-5">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </Col>
        )}
      </Row>
    );
  }

  return (
    <Container className="my-4">
      <Row>
        <Col xs={12}>
          {!isLoading ? (
            <PaginatedItems itemsPerPage={isMobile ? 4 : 12} />
          ) : (
            <Load />
          )}
        </Col>
      </Row>
    </Container>
  );
}
