import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { isMobile } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import InterSection from "../Utils/InterSection";
import TarjetaTarifa from "../Tarjeta/TarjetaTarifa";
import NotInfoItem from "../Utils/NotInfoItem";
import Load from "../Utils/Load";
import { useLocation } from "react-router-dom";
import { useFetchData } from "../../services/Query";

function ContenedorProductosMovilFibraTv() {
  // Estado para filtros de precio y capacidad
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [minCapacity, setMinCapacity] = useState(0);
  const [maxCapacity, setMaxCapacity] = useState(1000);
  const [typeMoneda, setTypeMoneda] = useState(null);

  // Estados para el estado de carga de filtros e información
  const [isLoadFilter, setIsLoadFilter] = useState(false);
  const [isLoadInformation, setIsLoadInformation] = useState(false);

  // Estados para filtros seleccionados
  const [filterBrand, setFilterBrand] = useState([]);
  const [filterPrice, setFilterPrice] = useState([minPrice, maxPrice]);
  const [filterCapacity, setFilterCapacity] = useState([
    minCapacity,
    maxCapacity,
  ]);
  const [filterLlamadas, setFilterLlamadas] = useState(false);
  const [filterPromo, setFilterPromo] = useState(false);
  const [filterOfertaDestacada, setFilterOfertaDestacada] = useState(false);

  // Estados para tarifas y marcas
  const [Tarifas, setTarifas] = useState([]);
  const [filtros, setFiltros] = useState([]);
  const [brand, setBrand] = useState([]);

  const [countParticulares, setCountParticulares] = useState(0);
  const [countEmpresarial, setCountEmpresarial] = useState(0);

  // Estados para rangos de precio y capacidad
  const [rangePrice, setRangePrice] = useState([minPrice, maxPrice]);
  // Estado para el modal de filtros
  const [show, setShow] = useState(false);
  const [lang, setLang] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLang(location.pathname.split("/")[1]);
  }, [location]);
  // Función para limpiar los filtros
  const cleanFilter = () => {
    setFilterLlamadas(false);
    setFilterPromo(false);
    setFilterBrand([]);
    setFilterCapacity([minCapacity, maxCapacity]);
    setFilterPrice([minPrice, maxPrice]);
    setRangePrice([minPrice, maxPrice]);
    setFilterOfertaDestacada(false);
    setFiltros(Tarifas);
  };


  // Función para manejar el cambio en el rango de precio
  const handleRangeChangePrice = (newRange) => {
    setRangePrice(newRange);
    handleFilterPrice(newRange);
  };

  // Consulta para obtener las marcas
  const { data: fetchedFilters } = useFetchData("filterMovilFibraTv", lang);

  // Función para obtener los datos iniciales de filtros
  useEffect(() => {
    if (lang != null) {
      setIsLoadFilter(false);
      if (fetchedFilters && fetchedFilters.length) {
        const { min_gb, max_gb, min_precio, max_precio, moneda } =
          fetchedFilters[0];

        setMinCapacity(parseInt(min_gb) > 0 ? parseInt(min_gb) : 0);
        setMaxCapacity(parseInt(max_gb));

        setMaxPrice(parseInt(max_precio));
        setMinPrice(parseInt(min_precio) > 0 ? parseInt(min_precio) : 0);
        setRangePrice([
          parseInt(min_precio) > 0 ? parseInt(min_precio) : 0,
          parseInt(max_precio),
        ]);
        setTypeMoneda(moneda);
        setIsLoadFilter(true);
      }
    }
  }, [lang, fetchedFilters]);

  // Llamada a la API para obtener las marcas
  const { data: fetchedBrand } = useFetchData("Operadoras/movilfibratv", lang);

  // Función para obtener las marcas de operadoras
  useEffect(() => {
    if (lang != null) {
      if (fetchedBrand) {
        setBrand(fetchedBrand);
      }
    }
  }, [lang, fetchedBrand]);

  // Llamada a la API para obtener las tarifas
  const { data: fetchedTarifas } = useFetchData("TarifasFibraMovilTv", lang);

  // Función para obtener las tarifas de móvil
  useEffect(() => {
    if (lang != null) {
      setIsLoadInformation(true);
      if (fetchedTarifas) {
        setFiltros(fetchedTarifas);
        setTarifas(fetchedTarifas);
        const particulares = fetchedTarifas?.filter(
          (item) => item.tarifa_empresarial === 2
        ).length;
        const empresarial = fetchedTarifas?.filter(
          (item) => item.tarifa_empresarial === 1
        ).length;
        setCountEmpresarial(empresarial);
        setCountParticulares(particulares);
        setIsLoadInformation(false);
      }
    }
  }, [brand, lang, fetchedTarifas]);

  function setFilterBrandMulti(value) {
    if (!filterBrand?.includes(value)) {
      setFilterBrand([...filterBrand, value]);
    } else {
      setFilterBrand(filterBrand.filter((item) => item !== value));
    }
  }

  // Función para manejar el filtro de precio
  const handleFilterPrice = (value) => {
    setFilterPrice(value);
  };

  // Función para filtrar por marca
  const filterByBrand = useCallback(
    (item) => {
      if (filterBrand.length > 0) {
        return filterBrand.includes(item.operadora);
      }
      return true;
    },
    [filterBrand]
  );

  // Función para filtrar por precio
  const filterByPrice = useCallback(
    (item) =>
      filterPrice !== null
        ? item.precio >= filterPrice[0] && item.precio <= filterPrice[1]
        : true,
    [filterPrice]
  );

  // Función para filtrar por tecnología
  const filterByTechnology = useCallback(
    (item) =>
      filterLlamadas !== false
        ? filterByFilter(filterLlamadas, item, "ilimitadas")
        : true,
    [filterLlamadas] // Dependencia de filterLlamadas
  );

  // Función para filtrar por promocion
  const filterByPromo = useCallback(
    (item) =>
      filterPromo !== false
        ? item.promocion !== "" && item.promocion !== null
        : true,
    [filterPromo]
  );

  const filterByOfertaDestacada = useCallback(
    (item) => (filterOfertaDestacada !== false ? item.destacada === 1 : true),
    [filterOfertaDestacada]
  );

  // Función para aplicar los filtros
  useEffect(() => {
    const resultado = Tarifas.filter((item) => filterByBrand(item))
      .filter((item) => filterByPrice(item))
      .filter((item) => filterByTechnology(item))
      .filter((item) => filterByPromo(item))
      .filter((item) => filterByOfertaDestacada(item));
    const particulares = resultado?.filter(
      (item) => item.tarifa_empresarial === 2
    ).length;
    const empresarial = resultado?.filter(
      (item) => item.tarifa_empresarial === 1
    ).length;
    setCountParticulares(particulares);
    setCountEmpresarial(empresarial);
    setFiltros(resultado);
    setCountParticulares(
      resultado?.filter((item) => item.tarifa_empresarial === 2).length
    );
  }, [
    filterBrand,
    filterPrice,
    filterCapacity,
    filterLlamadas,
    filterPromo,
    filterOfertaDestacada,
    Tarifas,
    filterByBrand,
    filterByOfertaDestacada,
    filterByPrice,
    filterByPromo,
    filterByTechnology
  ]);

  // Función para filtrar por palabra clave en los bloques
  function filterByFilter(filter, item, word) {
    if (filter !== false) {
      if (item.parrilla_bloque_1?.toLowerCase().includes(word?.toLowerCase())) {
        return true;
      } else if (
        item.parrilla_bloque_2?.toLowerCase().includes(word?.toLowerCase())
      ) {
        return true;
      } else if (
        item.parrilla_bloque_3?.toLowerCase().includes(word?.toLowerCase())
      ) {
        return true;
      } else if (
        item.parrilla_bloque_4?.toLowerCase().includes(word?.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return (
    <>
      <section>
        <Container>
          <Row className="justify-content-around">
            <Col xs={12} md={12} xl={3}>
              <Row>
                {!isMobile ? (
                  <Col className="my-3 font-bold" xs={6} md={5}>
                    Filtrar por:{" "}
                  </Col>
                ) : (
                  <Col className="my-2" xs={6} md={5}>
                    <Button variant="light" onClick={() => setShow(true)}>
                      Filtrar por
                    </Button>
                  </Col>
                )}
                <Col className="my-2 text-center" xs={6} md={7}>
                  <button className="btn btn-light" onClick={cleanFilter}>
                    Limpiar filtro
                  </button>
                </Col>
                <hr />
              </Row>
              {isMobile ? (
                <Modal show={show} onHide={() => setShow(false)}>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Row>
                      {isMobile && (
                        <Col xs={12} key={filterBrand} className="my-2" md={6}>
                          Se encontraron{" "}
                          <span className="font-bold">{filtros?.length}</span>{" "}
                          resultados de{" "}
                          <span className="font-bold">{Tarifas.length}</span>
                        </Col>
                      )}
                      <Col md={12}>
                        <span className="font-bold">Compañía:</span>
                      </Col>
                      {brand?.length > 0 &&
                        brand.map((item, index) => (
                          <Col xs={4} md={6} key={item.id}>
                            <button
                              className={`filtro-producto-logo my-2 ${
                                filterBrand.includes(item.id) ? "logoFocus" : ""
                              }`}
                              value={item.nombre}
                              onClick={() => setFilterBrandMulti(item.id)}
                            >
                              <img src={item.logo} alt={item.nombre} />
                            </button>
                          </Col>
                        ))}
                    </Row>
                    <Row>
                      <div className="mt-4">
                        <b>{"Coste mensual"}:</b>
                        <div className="my-4 d-flex justify-content-between">
                          <div>
                            {typeMoneda}
                            {rangePrice[0]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </div>
                          <div>
                            {typeMoneda}
                            {rangePrice[1]
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </div>
                        </div>
                        <Slider
                          range
                          min={minPrice}
                          max={maxPrice}
                          value={rangePrice}
                          onChange={handleRangeChangePrice}
                          className="form-input-range"
                        />
                      </div>
                      <div className="mt-4">
                        <b>{"Oferta destacada"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterOfertaDestacada}
                            onChange={() =>
                              setFilterOfertaDestacada(!filterOfertaDestacada)
                            }
                            label={"Mostrar solo ofertas destacadas"}
                            reverse
                          />
                        </div>
                      </div>
                      <div className="my-2">
                        <b>{"Llamadas ilimitadas"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterLlamadas}
                            onChange={() => setFilterLlamadas(!filterLlamadas)}
                            label={"Llamadas ilimitadas"}
                            reverse
                          />
                        </div>
                      </div>
                      <div className="my-2">
                        <b>{"Promoción"}:</b>
                        <div className="my-2">
                          <Form.Switch
                            className="input-check-dark mt-2 text-left"
                            type="switch"
                            checked={filterPromo}
                            onChange={() => setFilterPromo(!filterPromo)}
                            label={"Tiene promoción"}
                            reverse
                          />
                        </div>
                      </div>
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" onClick={() => setShow(false)}>
                      Filtrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              ) : (
                <>
                  {isLoadFilter && !isLoadInformation ? (
                    <>
                      <Row>
                        {isMobile && (
                          <Col
                            xs={12}
                            key={filterBrand}
                            className="my-2"
                            md={6}
                          >
                            Se encontraron{" "}
                            <span className="font-bold">{filtros?.length}</span>{" "}
                            resultados de{" "}
                            <span className="font-bold">{Tarifas.length}</span>
                          </Col>
                        )}
                        <Col md={12}>
                          <span className="font-bold">Compañía:</span>
                        </Col>
                        {brand?.length > 0 &&
                          brand.map((item, index) => (
                            <Col xs={4} md={6} key={item.id}>
                              <button
                                className={`filtro-producto-logo my-2 ${
                                  filterBrand.includes(item.id)
                                    ? "logoFocus"
                                    : ""
                                }`}
                                value={item.nombre}
                                onClick={() => setFilterBrandMulti(item.id)}
                              >
                                <img src={item.logo} alt={item.nombre} />
                              </button>
                            </Col>
                          ))}
                      </Row>
                      <Row>
                        <div className="mt-4">
                          <b>{"Coste mensual"}:</b>
                          <div className="my-4 d-flex justify-content-between">
                            <div>
                              {typeMoneda}
                              {rangePrice[0]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                            <div>
                              {typeMoneda}
                              {rangePrice[1]
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </div>
                          </div>
                          <Slider
                            range
                            min={minPrice}
                            max={maxPrice}
                            value={rangePrice}
                            onChange={handleRangeChangePrice}
                            className="form-input-range"
                          />
                        </div>
                        <div className="mt-4">
                          <b>{"Oferta destacada"}:</b>
                          <div className="my-2">
                            <Form.Switch
                              className="input-check-dark mt-2 text-left"
                              type="switch"
                              checked={filterOfertaDestacada}
                              onChange={() =>
                                setFilterOfertaDestacada(!filterOfertaDestacada)
                              }
                              label={"Mostrar solo ofertas destacadas"}
                              reverse
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <b>{"Llamadas ilimitadas"}:</b>
                          <div className="my-2">
                            <Form.Switch
                              className="input-check-dark mt-2 text-left"
                              type="switch"
                              checked={filterLlamadas}
                              onChange={() =>
                                setFilterLlamadas(!filterLlamadas)
                              }
                              label={"Llamadas ilimitadas"}
                              reverse
                            />
                          </div>
                        </div>
                        <div className="my-2">
                          <b>{"Promoción"}:</b>
                          <div className="my-2">
                            <Form.Switch
                              className="input-check-dark mt-2 text-left"
                              type="switch"
                              checked={filterPromo}
                              onChange={() => setFilterPromo(!filterPromo)}
                              label={"Tiene promoción"}
                              reverse
                            />
                          </div>
                        </div>
                      </Row>
                    </>
                  ) : (
                    <Load />
                  )}
                </>
              )}
            </Col>
            <Col md={12} xl={8}>
              <Row>
                <Col key={filterBrand} className="my-2" md={6}>
                  Mostrando:{" "}
                  <span className="font-bold">{filtros?.length}</span>{" "}
                  resultados de{" "}
                  <span className="font-bold">{Tarifas.length}</span>
                </Col>
              </Row>
              <Row>
                {countParticulares === 0 && countEmpresarial === 0 ? (
                  <NotInfoItem
                    title="No se encontraron ofertas"
                    text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                  />
                ) : (
                  <Tabs
                    defaultActiveKey="particulares"
                    id="tabs_filtros"
                    className="mb-3"
                  >
                    {countParticulares > 0 && (
                      <Tab
                        eventKey="particulares"
                        title={
                          <>
                            Tarifas para particulares{" "}
                            <span className="badge bg-secundary color-dark ms-2">
                              {countParticulares}
                              {
                                filtros?.filter(
                                  (item) => item.tarifa_empresarial === 2
                                ).length
                              }
                            </span>
                          </>
                        }
                      >
                        {(() => {
                          const filteredTarifas = filtros?.filter(
                            (item) => item.tarifa_empresarial === 2
                          );

                          return !isLoadInformation ? (
                            filteredTarifas?.length > 0 ? (
                              filteredTarifas.map((item, index) => (
                                <TarjetaTarifa
                                  key={index}
                                  data={item}
                                  TarifaCard
                                />
                              ))
                            ) : (
                              <NotInfoItem
                                title="No se encontraron ofertas"
                                text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                              />
                            )
                          ) : (
                            <Load />
                          );
                        })()}
                      </Tab>
                    )}

                    {countEmpresarial > 0 && (
                      <Tab
                        eventKey="empresariales"
                        title={
                          <>
                            Tarifas para empresas{" "}
                            <span className="badge bg-secundary color-dark ms-2">
                              {countEmpresarial}
                            </span>
                          </>
                        }
                      >
                        {(() => {
                          const filteredTarifas = filtros?.filter(
                            (item) => item.tarifa_empresarial === 1
                          );

                          return !isLoadInformation ? (
                            filteredTarifas?.length > 0 ? (
                              filteredTarifas.map((item, index) => (
                                <TarjetaTarifa
                                  key={index}
                                  data={item}
                                  TarifaCard
                                />
                              ))
                            ) : (
                              <NotInfoItem
                                title="No se encontraron ofertas"
                                text="Lo sentimos, no hemos encontrado ofertas con los filtros seleccionados."
                              />
                            )
                          ) : (
                            <Load />
                          );
                        })()}
                      </Tab>
                    )}
                  </Tabs>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <InterSection></InterSection>
    </>
  );
}

export default ContenedorProductosMovilFibraTv;
